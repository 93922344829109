<template>
  <div>
    <b-row class="mb-2">
      <b-col cs="6" sm="6" md="8" class="mr-auto">
        <go-back :to="{ name: 'verification-list' }"
                 :title="$t('actions.verify')"/>
      </b-col>
    </b-row>
    <b-row class="justify-content-around">
      <b-col md="5" sm="6">
        <photo-selector :draggableText="true" @change="(file) => (photoA = file)" />
      </b-col>
      <b-col md="5" sm="6">
        <photo-selector :draggableText="true" @change="(file) => (photoB = file)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="2" class="mx-auto">
        <b-button
          variant="primary"
          block
          :disabled="(!photoA && !photoB) || loading"
          @click="verify"
        >
          <template v-if="loading">
            <b-spinner small />
            <span class="sr-only"> {{ $t("general.loading") }}</span>
          </template>
          <template v-else>
            {{ $t("actions.verify") }}
          </template>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { store } from "@/api/verifications.api";
import { Cropper } from "vue-advanced-cropper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-advanced-cropper/dist/style.css";
import PhotoSelector from "./components/PhotoSelector.vue";
import GoBack from "@/components/Common/GoBack";

export default {
  data() {
    return {
      selectedPhoto: null,
      file: null,
      loading: false,
      croppedFile: null,
      selector: null,
      photoA: null,
      photoB: null,
    };
  },
  components: {GoBack, Cropper, PhotoSelector },
  methods: {
    async verify() {
      this.loading = true;
      const data = new FormData();
      data.append("photo_a", this.photoA);
      data.append("photo_b", this.photoB);
      const { data: result, error } = await store(data);

      if (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.title || this.$t("common.errors.unpected"),
            icon: "XIcon",
            variant: "danger",
          },
        });
        this.loading = false;
        return;
      }

      this.$router.push({
        name: "verification-show",
        params: { id: result.id },
      });
    },
  },
};
</script>

<style  lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem 1rem;
}

.upload-button {
  width: 100% !important;
  aspect-ratio: 1/1;
}

.upload-button {
  aspect-ratio: 1/1;
  position: relative;
  background-color: #0003;
  overflow: clip;
  border-radius: 0.5rem;
  background-blend-mode: color-burn;
  transition: background-color ease 0.2s;

  .plus-icon {
    cursor: pointer;
    padding: 20%;
    opacity: 0.8;
    transition: opacity ease-in 0.2s, padding ease-in 0.2s;
  }
  .plus-icon,
  img {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: auto;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #0004;
    transition: background-color ease 0.2s;

    .plus-icon {
      opacity: 1;
      padding: 18%;
      transition: opacity ease-in 0.2s, padding ease-in 0.2s;
    }
  }

  .button-group {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  img {
    object-fit: cover;
  }
}
.cropper {
  max-width: 100%;
  height: 100%;
  max-height: 600px;
  background: #000;
}
</style>