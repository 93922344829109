<template>
  <b-card class="mx-auto" style="max-width: 500px">
    <div class="upload-button" @dragover="dragover" @dragleave="dragleave" @drop="drop"
         :class="{'invalid-file': hasFileError}" tabindex="0" title="Add a photo">
      <template v-if="!src">
        <span class="plus-icon-title">
          {{ $t("common.actions.select_photo") }}
          <br>
          <span v-if="draggableText" class="font-small-2">or drag&drop here</span>
        </span>
        <feather-icon
            class="plus-icon"
            icon="PlusIcon"
            @click="$refs.file.click()"
        />
      </template>
      <img :src="cropped" v-else-if="cropped"/>

      <cropper
          class="cropper"
          image-restriction="stencil"
          :auto-zoom="true"
          :src="src"
          v-else
          @change="onCrop"
          :stencil-props="{
          handlers: {
            eastNorth: true,
            north: false,
            westNorth: true,
            west: false,
            westSouth: true,
            south: false,
            eastSouth: true,
            east: false,
          },
          movable: false,
          scalable: false,
          aspectRatio: 1,
        }"
      />

      <input
          type="file"
          ref="file"
          accept=".png,.jpeg,.jpg"
          style="display: none"
          @change="photoSelected"
      />
    </div>
    <b-button-group class="d-flex mt-2" style="gap: 2rem" v-if="src">
      <b-button
          class="btn-icon rounded"
          variant="danger"
          title="Clear new photo"
          @click="clearSelectedPhoto"
      >
        <feather-icon icon="XIcon"/>
        {{ $t("actions.clear") }}
      </b-button>
      <b-button
          class="btn-icon rounded"
          v-if="!cropped"
          variant="info"
          title="Crop Photo"
          @click="cropPhoto"
      >
        <feather-icon icon="CropIcon"/>
        Crop
      </b-button>
    </b-button-group>
    <b-card-footer class="pb-0" v-if="hasFileError">
      <div class="d-flex invalid-feedback" v-for="fileError in fileErrors" :key="fileError">
        <feather-icon icon="InfoIcon" class="mr-1"/>
        {{ fileError }}
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import FileValidationMixins from "@core/mixins/FileValidationMixins";

export default {
  data() {
    return {
      file: null,
      croppedFile: null,
      src: null,
      cropped: false,
    };
  },
  props: {
    draggableText: {
      default: false
    }
  },
  mixins: [FileValidationMixins],
  watch: {
    croppedFile() {
      this.$emit("change", this.croppedFile);
    },
  },
  methods: {
    clearSelectedPhoto() {
      this.file = null;
      this.croppedFile = null;
      this.src = null;
      this.cropped = false;
      this.$refs.file.value = "";
    },
    photoSelected(event) {
      this.fileErrors = []
      const file = event.target.files[0];
      if (file) {
        this.handleFile(file)
      }
    },
    handleFile(file) {
      if (!this.validateFile(file)) {
        this.file = file;
        this.src = URL.createObjectURL(file);
      }
    },
    onCrop({canvas}) {
      canvas.toBlob((file) => (this.croppedFile = file), this.file.type);
    },
    cropPhoto() {
      this.cropped = URL.createObjectURL(this.croppedFile);
    },
  },
};
</script>


<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem 1rem;
}

.upload-button {
  width: 100% !important;
  aspect-ratio: 1/1;
}

.upload-button {
  aspect-ratio: 1/1;
  position: relative;
  background-color: #0003;
  overflow: clip;
  border-radius: 0.5rem;
  background-blend-mode: color-burn;
  transition: all ease 0.2s;

  .plus-icon {
    cursor: pointer;
    padding: 20%;
    opacity: 0.8;
    transition: opacity ease-in 0.2s, padding ease-in 0.2s;
  }

  .plus-icon,
  img {
    position: absolute;
    width: 100%;
    height: 100%;

    margin: auto;
  }

  .plus-icon-title {
    position: absolute;
    bottom: 18%;
    width: 100%;
    text-align: center;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: #0004;
    transition: background-color ease 0.2s;

    .plus-icon {
      opacity: 1;
      padding: 18%;
      transition: opacity ease-in 0.2s, padding ease-in 0.2s;
    }
  }

  .button-group {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  img {
    object-fit: cover;
  }
}

.cropper {
  max-width: 100%;
  height: 100%;
  max-height: 600px;
  background: #000;
}
</style>